/**
 * @ngInject
 */
function Directive($http, $window) {
  return {
    scope: {},
    bindToController: {
      imageUrl: '@',
      useSvgPanZoom: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'foundryLogo',
    require: ['foundryLogo', '?ngModel'],
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: '/angular_templates/directives/foundry_logo.html',

    link: function(scope, element, attrs, requiredControllers) {
      var foundryLogoController = requiredControllers[0];
      var ngModel = requiredControllers[1];

      foundryLogoController.ngModel = ngModel;
      foundryLogoController.element = element[0];
      _initSvgEl(foundryLogoController.getSvgEl());

      /**
       * Initialize the svg object. Loads the svg image and initializes the
       * event handlers for it.
       */
      function _initSvgEl(svgEl) {
        if (!svgEl || !foundryLogoController.imageUrl) {
          return;
        }

        // We need to download the SVG image as a blob and then create a data
        // url that will be set as the data attribute for the object tag.
        //
        // This workaround allows us to manipulate the content of the SVG document. Without it
        // the browser raises an error, due to cross origin limitations.
        $http.get(foundryLogoController.imageUrl, {
          responseType: 'blob'
        }).then(function(response) {
          svgEl.data = $window.URL.createObjectURL(response.data);
          foundryLogoController.isSvgLoaded = true;
          // Initialize pan and zoom to avoid centering issues with div which have fixed height and width and padding.
          // If we don't do this, it'll create scroll in the div.
          // For divs which do not have fixed height/width/padding, we can avoid initializing pan and zoom and just render
          // the svg as it is.
          if (foundryLogoController.useSvgPanZoom === undefined || foundryLogoController.useSvgPanZoom === 'true') {
            svgEl.onload = function() {
              foundryLogoController.initSvg();
            };
          }
        });
      }
    }
  };
}

module.exports = Directive;
