/**
 * Clicking on an element with `dropdown-base-button` will toggle the
 * open/closed state of the parent `dropdown-base` element.
 *
 * `dropdown-base-button` must be nested inside of an element with the
 * `dropdown-base` directive.
 *
 * See `dropdown-base` for more documentation.
 *
 * @ngInject
 */
function DropdownBaseButton() {
  return {
    // The parent controller is imported automatically because it's declared as
    // a requirement below.
    link: function(scope, element, attrs, dropdownBaseController) {
      element.addClass(dropdownBaseController.getMenuButtonClass());

      element.on('click', function() {
        scope.$apply(function() {
          dropdownBaseController.toggleMenu();
        });
      });
    },

    // Require the parent controller to allow this directive to control the
    // state of the overall dropdown
    require: '^dropdownBase',
    restrict: 'A'
  };
}

module.exports = DropdownBaseButton;
