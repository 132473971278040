/**
 * The content for a `dropdown-base` is contained within an element
 * that is annotated with `dropdown-base-content`.
 *
 * `dropdown-base-content` must be nested within an element with the
 * `dropdown-base` directive.
 *
 * See `dropdown-base` for documentation.
 *
 * @ngInject
 */
function DropdownBaseContent() {
  return {
    // The parent controller is imported automatically because
    // it's declared as a requirement below.
    link: function(scope, element, attrs, dropdownBaseController) {
      element.addClass(dropdownBaseController.getContentClass());

      scope.$watch(function() {
        return dropdownBaseController.isOpen;
      }, function () {
        element.toggleClass(
          dropdownBaseController.getOpenClass(), dropdownBaseController.isOpen);
      });

      element.on('keydown', function(event) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
          dropdownBaseController.navigateMenu(event);
        }
        if (event.key === 'Tab') {
          dropdownBaseController.toggleMenu();
          element.toggleClass(
            dropdownBaseController.getOpenClass(), dropdownBaseController.isOpen);
        }
      });
    },

    // Require the parent dropdown-base's controller to allow this directive
    // to have access to information about the overall dropdown-base.
    require: '^dropdownBase',
    restrict: 'A'
  };
}

module.exports = DropdownBaseContent;
