/**
 * @ngInject
 */
function SpectrumDropdownController($timeout) {
  this.$timeout = $timeout;
  this.prioritizedItem = this.prioritize();
}

/**
 * Returns the current label for the dropdown.
 * @returns {String}
 */
SpectrumDropdownController.prototype.getLabel = function() {
  // If the menu doesn't have a selected value, display the placeholder text if
  // it's available.
  if (!this.selectedValue) {
    return this.getPlaceholderText();
  }

  var menuItems = this.menuItems();
  for (var i = 0; i < menuItems.length; i++) {
    if (menuItems[i].value == this.selectedValue) {
      return menuItems[i].label;
    }
  }
};

/**
 * Handles the model change event.
 */
SpectrumDropdownController.prototype.handleChange = function() {
  var self = this;

  // Handle the change event inside of a timeout so that the change event will
  // take place after the digest loop.
  self.$timeout(function() {
    self.onSelectedItemChange();
  });
};

SpectrumDropdownController.prototype.getPlaceholderText = function() {
  if (this.placeholderText && this.placeholderText.trim().length > 0) {
    return this.placeholderText.trim();
  }
};

module.exports = SpectrumDropdownController;
