/**
 * Directive for a Spectrum style dropdown menu.
 *
 * The `menu-items` attribute needs to be bound to an array of menu objects that have a label
 * and a value:
 *
 *    this.menuItems = [
 *      { label: 'item 1', value: 'item-1' },
 *      { label: 'Item 2', value: 'item-2' },
 *      { label: 'Item 3', value: 'item-3' }
 *    ];
 *
 * The `selected-item` attribute will data bind to the currently selected item.
 *
 * @example
 *
 *    <spectrum-dropdown menu-items="parentCtrl.menuItems"
 *                       selected-value="parentCtrl.selectedItem.value"
 *                       on-selected-item-change="parentCtrl.handleChange()">
 *    </spectrum-dropdown>
 *
 * @ngInject
 */
function SpectrumDropdownDirective() {
  return {
    scope: {},
    bindToController: {
      menuItems: '&',
      prioritize: '&',
      selectedValue: '=',
      onSelectedItemChange: '&',
      triggerButtonDataId: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'spectrumDropdown',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/spectrum/dropdown.html',
    transclude: true,

    link: function(scope, el, attrs, ctrl, transclude) {
      ctrl.placeholderText = transclude().text();
    }
  };
}

module.exports = SpectrumDropdownDirective;
