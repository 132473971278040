/**
 * This directive creates an attribute that can be added to any elements that are displayed
 * inside of the fontpack hero. The attribute must specify a variation:
 *
 *   <fontpack-hero>
 *     <div fontpack-hero-variation="controller.variation">
 *       Variation
 *     </div>
 *   </fontpack-hero>
 *
 * This variation must have a `variation_id` which will be used to determine if an
 * extra "used" or "selected" class should be attached to the element.
 *
 * `fontpack-hero-variation` must be nested inside of an element with the
 * `fontpack-hero` directive.
 *
 * @ngInject
 */
function FontpackHeroVariation() {
  return {
    scope: {
      fontpackHeroVariation: '&'
    },

    // The parent fontpack hero controller is imported automatically because
    // it's declared as a requirement below.
    link: function(scope, element, attrs, fontpackHeroController) {
      // Keep track of the original class that was attached to the element so
      // we don't lose while changing the extra classes attached to the element.
      var originalClass = attrs.class;

      element.on('click', function() {
        fontpackHeroController.handleVariationClick(scope.fontpackHeroVariation());
      });

      _initWatchers();

      /**
       * Initialize watchers for properties on the parent controller.
       * @private
       */
      function _initWatchers() {
        // Watch the selected group ids so we can update the class for the variation to
        // indicate if it's used in one of the selected groups.
        scope.$watchCollection(function() {
          return fontpackHeroController.selectedGroupIds;
        }, function() {
          _updateElementClasses();
        });

        // Watch the selected variation so we can update the class for the variation
        // that is currently selected.
        scope.$watch(function() {
          return fontpackHeroController.selectedVariationId;
        }, function() {
          _updateElementClasses();
        });
      }

      /**
       * Update the class name for the container element so it indicates the right
       * selected state.
       * @private
       */
      function _updateElementClasses() {
        element[0].className = _getElementClass();
      }

      /**
       * Get the class name that will indicate the current state of the
       * variation. Also, preserve any of the original classes that specified
       * for the element.
       */
      function _getElementClass() {
        return [
          originalClass,
          fontpackHeroController.getVariationElementClass(scope.fontpackHeroVariation())
        ].join(' ');
      }
    },

    // Require the parent fontpack-hero's controller to allow this
    // directive to control the state of the overall fontpack-hero.
    require: '^fontpackHero',
    restrict: 'A'
  };
}

module.exports = FontpackHeroVariation;
