var FOUNDRY_SVG_SELECTOR = '.foundry-info--logo-box';
var svgPanZoom = require('svg-pan-zoom');
/**
 * @ngInject
 */
function FoundryLogoController($scope) {
  var self = this;
  self.$scope = $scope;
  self.isSvgLoaded = false;
}

/**
 * Get the Logo's SVG element.
 * @returns {Element}
 */
FoundryLogoController.prototype.getSvgEl = function() {
  return this.element.querySelector(FOUNDRY_SVG_SELECTOR);
};

/**
 * Initialize the SVG element
 */
FoundryLogoController.prototype.initSvg = function() {
  var self = this;
  // Use the svgPanZoom library to wrap the SVG element to fit inside container
  svgPanZoom(self.getSvgEl(), {
    fit: true,
    zoomEnabled: false
  });
};

module.exports = FoundryLogoController;
