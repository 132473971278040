const dropdownUtil = require('../../../util/dropdown_util.js');
const _ = require('underscore');

var DEFAULT_DROPDOWN_BUTTON_CLASS = 'adobe-fonts-select-menu__dropdown-button';
var DEFAULT_DROPDOWN_CLASS = 'adobe-fonts-select-menu';
var DEFAULT_DROPDOWN_CONTENT_CLASS = 'adobe-fonts-select-menu__content';
var DEFAULT_DROPDOWN_ITEMS_CLASS = 'adobe-fonts-select-menu__menu-items';
var DEFAULT_DROPDOWN_ITEM_CLASS = 'adobe-fonts-select-menu__menu-item';
var DEFAULT_DROPDOWN_ITEM_SELECTED_CLASS = 'is-selected';
var DEFAULT_DROPDOWN_OPEN_CLASS = 'is-open';

/**
 * @ngInject
 */
function DropdownBaseController($element) {
  this.$element = $element;
  this.isOpen = false;
}

/**
 * Returns the class that will be attached to the parent element for the
 * directive.
 * @return {String}
 */
DropdownBaseController.prototype.getClass = function() {
  return DEFAULT_DROPDOWN_CLASS;
};

/**
 * Returns the class that will be attached to the content element.
 * @return {String}
 */
DropdownBaseController.prototype.getContentClass = function() {
  return DEFAULT_DROPDOWN_CONTENT_CLASS;
};

/**
 * Returns the class that will be attached to the menu button.
 * @return {String}
 */
DropdownBaseController.prototype.getMenuButtonClass = function() {
  return DEFAULT_DROPDOWN_BUTTON_CLASS;
};

/**
 * Returns the class that will be attached to menu items
 * @return {String}
 */
DropdownBaseController.prototype.getMenuItemClass = function() {
  return DEFAULT_DROPDOWN_ITEM_CLASS;
};

/**
 * Returns the class that will be attached to selected menu items.
 * @return {String}
 */
DropdownBaseController.prototype.getMenuItemSelectedClass = function() {
  return DEFAULT_DROPDOWN_ITEM_SELECTED_CLASS;
};

/**
 * Returns the class that will be attached to the element when the menu is open.
 * @return {String}
 */
DropdownBaseController.prototype.getOpenClass = function() {
  return DEFAULT_DROPDOWN_OPEN_CLASS;
};

/**
 * Returns the class that will be attached to the container for the menu items.
 * @return {String}
 */
DropdownBaseController.prototype.getMenuItemsClass = function() {
  return DEFAULT_DROPDOWN_ITEMS_CLASS;
};

/**
 * Returns the current value for the menu.
 * @return {Object}
 */
DropdownBaseController.prototype.getValue = function() {
  if (this.ngModel) {
    return this.ngModel.$viewValue;
  }
};

/**
 * Toggles the state of the select menu element.
 */
DropdownBaseController.prototype.toggleMenu = function(state) {
  if (state === undefined) {
    this.isOpen = !this.isOpen;
  } else {
    this.isOpen = state;
  }
  this.toggleMenuItemsAttributes(this.isOpen);
  this.$element.toggleClass(this.getOpenClass(), this.isOpen);
};

/**
 * Update the underlying ngModel value of the menu if one exists.
 * @param {Object} value
 */
DropdownBaseController.prototype.updateValue = function(value) {
  // ngModel will only be present if an "ng-model" attribute was set for the
  // parent element.
  if (this.ngModel) {
    this.ngModel.$setViewValue(value);
  }
};

DropdownBaseController.prototype.navigateMenu = function(event) {
  dropdownUtil.navigateDropdown(event, this.menuButton, this.menuItemsList);
};

/**
 * Toggle the dropdown's menu options for screen readers and keyboard tab order
 *
 * @param {Boolean} isOpen
 */
DropdownBaseController.prototype.toggleMenuItemsAttributes = function(isOpen) {
  if (this.menuItems.length === 0) {
    return;
  }

  _.forEach(this.menuItems, function(item) {
    item.ariaSelected = !!_.includes(item.classList, DEFAULT_DROPDOWN_ITEM_SELECTED_CLASS);
    item.tabIndex = isOpen ? 0 : -1;
  });
};


module.exports = DropdownBaseController;
