/**
 * @ngInject
 */
function Directive($http, $window) {
  return {
    scope: {},
    bindToController: {
      groups: '&',
      heroImageFocus: '@',
      imageUrl: '@',
      static: '@'
    },
    controller: require('./controller.js'),
    controllerAs: 'fontpackHero',
    require: ['fontpackHero', '?ngModel'],
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: '/angular_templates/directives/fontpack_hero.html',

    link: function(scope, element, attrs, requiredControllers) {
      var fontpackHeroController = requiredControllers[0];
      var ngModel = requiredControllers[1];

      fontpackHeroController.ngModel = ngModel;
      fontpackHeroController.element = element[0];
      _initSvgEl(fontpackHeroController.getSvgEl());

      /**
       * Initialize the svg object. Loads the svg image and initializes the
       * event handlers for it.
       */
      function _initSvgEl(svgEl) {
        if (!svgEl || !fontpackHeroController.imageUrl || fontpackHeroController.isStaticFontpack()) {
          return;
        }

        // For some reason, the SVG image fetch requests were coming back
        // completely empty in Chrome, which lead to a CORS error in the
        // console, and no SVG image. Disabling the browser cache fixes the
        // issue, so it seems to cache related.
        //
        // Adding a timestamp to the request is a workaround that prevents the
        // browser from pulling the image from cache and stops the issue.
        const timestamp = new Date().toISOString();

        // We need to download the SVG image as a blob and then create a data
        // url that will be set as the data attribute for the object tag.
        //
        // This workaround allows us to manipulate the content of the SVG document. Without it
        // the browse raises an error, due to cross origin limitations.
        $http.get(fontpackHeroController.imageUrl + `?${timestamp}`, {
          responseType: 'blob'
        }).then(function(response) {
          svgEl.data = $window.URL.createObjectURL(response.data);
          svgEl.onload = function() {
            fontpackHeroController.initSvg();
          };
        });
      }
    }
  };
}

module.exports = Directive;
