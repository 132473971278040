var ZOOM_RESET_BUTTON_CLASS = 'button fontpack-hero__zoom-reset-button';

/**
 * Clicking on an element with `fontpack-hero-zoom-reset-button` zoom the hero
 * element back to the default zoom level.
 *
 * `fontpack-hero-zoom-reset-button` must be nested inside of an element with the
 * `fontpack-hero` directive.
 *
 * @ngInject
 */
function FontpackHeroZoomResetButton() {
  return {
    // The parent fontpack hero controller is imported automatically because
    // it's declared as a requirement below.
    link: function(scope, element, attrs, fontpackHeroController) {
      element.on('click', function() {
        fontpackHeroController.zoomReset();
      });

      element.addClass(ZOOM_RESET_BUTTON_CLASS);
    },

    // Require the parent fontpack-hero's controller to allow this
    // directive to control the state of the overall fontpack-hero.
    require: '^fontpackHero',
    restrict: 'A'
  };
}

module.exports = FontpackHeroZoomResetButton;
