var angular = require('angular');
var ESC_KEY_CODE = 'Escape';

/**
 * To create a custom dropdown menu:
 *  * Add `dropdown-base` as an attribute to the parent element for the menu.
 *  * Add `dropdown-base-button` to the element you would like to trigger the menu.
 *  * Add 'dropdown-base-content` to the element that contains the menu content.
 *  * Add 'dropdown-base-item` to the element that represents the menu item.
 *
 * For example:
 *
 *  <div dropdown-base
 *       ng-model="controller.menuItems"
 *       ng-change="controller.handleMenuChange()">
 *    <button dropdown-base-button>
 *      Open Menu
 *    </button>
 *
 *    <ul dropdown-base-content>
 *      <li dropdown-base-item
 *          value="item"
 *          ng-repeat="item in controller.menuItems">
 *        {{item.name}}
 *      </li>
 *    </ul>
 *  </div>
 *
 * @ngInject
 */
function DropdownBaseDirective() {
  return {
    bindToController: {
      dropdownBaseOptions: '&'
    },

    link: function(scope, element, attrs, controllers) {
      var dropdownBaseController = controllers[0];
      var ngModel = controllers[1];
      dropdownBaseController.ngModel = ngModel;

      element.addClass(dropdownBaseController.getClass());

      var options = dropdownBaseController.dropdownBaseOptions() || {};
      if (!options.disableBodyEvents) {
        _initBodyListeners(element, dropdownBaseController, scope);
      }

      dropdownBaseController.menuButton = element[0].querySelector(`.${dropdownBaseController.getMenuButtonClass()}`);
      dropdownBaseController.menuItemsList = element[0].querySelector(`.${dropdownBaseController.getMenuItemsClass()}`);
      dropdownBaseController.menuItems = dropdownBaseController.menuItemsList.children;
    },
    controller: require('./controller.js'),
    controllerAs: 'dropdownBase',
    require: ['dropdownBase', '?ngModel'],
    restrict: 'A'
  };
}

/**
 * Initializes event handlers that listen on the document body.
 *
 * @private
 * @param {angular.Element} element
 * @param {Object} controller
 * @param {Object} scope
 */
function _initBodyListeners(element, controller, scope) {
  // Close the menu if the user clicks outside it.
  angular.element(document.body).on('click', function(event) {
    if (!_isClickInsideElement(element[0], event.target)) {
      scope.$apply(function() {
        controller.toggleMenu(false);
      });
    }
  });

  // Close the menu when the user hits the esc key.
  angular.element(document.body).on('keydown', function(event) {
    if (event.key == ESC_KEY_CODE) {
      scope.$apply(function() {
        controller.toggleMenu(false);
      });
    }
  });
}

/**
 * Is the element a child of the target?
 *
 * @private
 * @param {Element}
 * @param {Element}
 */
function _isClickInsideElement(element, currentTarget) {
  if (currentTarget == element) {
    return true;
  }

  if (currentTarget.parentNode) {
    return _isClickInsideElement(element, currentTarget.parentNode);
  }

  return false;
}

module.exports = DropdownBaseDirective;
