var angular = require('angular');
var _ = require('underscore');
var svgPanZoom = require('svg-pan-zoom');

var DISABLED_OPACITY = 0.2;
var HERO_SVG_SELECTOR = '.fontpack-hero__svg';
var SVG_BACKGROUND_SELECTOR = '#background';
var SVG_VIEWPORT_SELECTOR = '#viewport';
var VARIATION_SELECTED_CLASS = 'fontpack-hero__variation--selected';
var VARIATION_USED_CLASS = 'fontpack-hero__variation--used';
var ZOOM_AMOUNT = 0.2;

/**
 * @ngInject
 */
function FontpackHeroController($scope, $window) {
  var self = this;
  self.$scope = $scope;
  self.MAX_ZOOM_LEVEL = 2;
  self.MIN_ZOOM_LEVEL = 0.5;

  // The SVG will be fully initialized once svgPanZoom is initialized
  self.isSvgInitialized = false;

  $window.addEventListener('resize', function() {
    self.$scope.$apply(function() {
      self._handleWindowResize();
    });
  });
}

/**
 * Adjusts the SVG's background element.
 *
 * The background group needs to cover the full width of the image, no
 * matter what the size is in the initial SVG file. To make this work,
 * we need to scale the background element appropriately.
 */
FontpackHeroController.prototype.adjustSvgBackground = function() {
  var background = this.getSvgBackground();
  if (!background) {
    return;
  }

  // Reset the scale of the background before getting the client width. This
  // will assure that bounds are based on regular, non-scaled version of the
  // background image.
  this.getSvgBackground().style.transform = 'scale(1, 1)';

  // Get the bounds of the full SVG element, and the background element
  var backgroundBounds = background.getBoundingClientRect();
  var svgElBounds = this.getSvgEl().getBoundingClientRect();

  // Use the bounds to calculate the amount that the background should be scaled
  // to fit the width of the image.
  var widthScale = svgElBounds.width / backgroundBounds.width;
  var heightScale = svgElBounds.height / backgroundBounds.height;

  this.getSvgBackground().style.transform = 'scale(' + widthScale + ',' + heightScale + ')';
};

/**
 * Returns the Hero SVG's background element.
 * @returns {Element}
 */
FontpackHeroController.prototype.getSvgBackground = function() {
  return this.getSvgEl().contentDocument.querySelector(SVG_BACKGROUND_SELECTOR);
};

/**
 * Get the Hero's SVG element.
 * @returns {Element}
 */
FontpackHeroController.prototype.getSvgEl = function() {
  return this.element.querySelector(HERO_SVG_SELECTOR);
};

/**
 * Get the Hero SVG's viewport.
 * @returns {Element}
 */
FontpackHeroController.prototype.getSvgViewport = function() {
  return this.getSvgEl().contentDocument.querySelector(SVG_VIEWPORT_SELECTOR);
};

/**
 * Handle click events on the SVG background element.
 */
FontpackHeroController.prototype.handleSvgBackgroundClick = function() {
  this._resetSelectedVariation();
};

/**
 * Handle click events on SVG group elements.
 * @param {Element} group
 */
FontpackHeroController.prototype.handleSvgGroupClick = function(group) {
  if (this._isSelectedGroup(group)) {
    this._resetSelectedGroups();
  } else {
    this._selectSvgGroup(group);
  }
};

/**
 * Handles clicks on variation items that are displayed within the hero element.
 * @param {Object} variation
 */
FontpackHeroController.prototype.handleVariationClick = function(variation) {
  if (!this.isSvgInitialized) {
    return;
  }

  if (this.selectedVariationId == variation.variation_id) {
    this._resetSelectedVariation();
  } else {
    this._selectVariation(variation);
  }
};

/**
 * Returns an extra class name that can be attached to a variation element
 * that's displayed inside of the hero container element. For example, for a
 * variation that's displayed as part of the list of variations for the
 * fontpack.
 *
 * @param {Object} variation
 * @returns {String}
 */
FontpackHeroController.prototype.getVariationElementClass = function(variation) {
  if (this.isVariationSelected(variation)) {
    return VARIATION_SELECTED_CLASS;
  }

  if (this.isVariationInSelectedGroups(variation)) {
    return VARIATION_USED_CLASS;
  }
};

/**
 * Initialize the SVG element
 */
FontpackHeroController.prototype.initSvg = function() {
  this.initSvgEvents();
  this.initSvgPanZoom();
  this.adjustSvgBackground();
};

/**
 * Initialize the custom event handles for the SVG element.
 */
FontpackHeroController.prototype.initSvgEvents = function() {
  var self = this;

  // Listen for click events on all of the SVG groups. Each group id will match up
  // with a group of variations in this collection.
  self.svgGroups = self.getSvgViewport().children;
  _.each(self.svgGroups, function(group) {
    angular.element(group).on('click', function(event) {
      event.stopPropagation();
      self.$scope.$apply(function() {
        self.handleSvgGroupClick(group);
      });
    });
  });

  // Clicking on the background element should reset all the groups back to
  // deselected.
  angular.element(this.getSvgBackground()).on('click', function(event) {
    event.stopPropagation();
    self.$scope.$apply(function() {
      self.handleSvgBackgroundClick();
    });
  });
};

/**
 * Use the svgPanZoom library to wrap the SVG element with extra
 * functionality and then store an instance of the library so we can add
 * custom controls.
 */
FontpackHeroController.prototype.initSvgPanZoom = function() {
  var self = this;
  self.svgElPanZoom = svgPanZoom(self.getSvgEl(), {
    center: true,
    contain: true,
    fit: false,
    customEventsHandler: {
      init: function(options) {
        self.$scope.$apply(function() {
          // Initialize the zoom level once the svg has been initialized.
          self.svgZoomLevel = options.instance.getZoom();
        });

        self.isSvgInitialized = true;
      }
    },

    mouseWheelZoomEnabled: false,
    viewportSelector: SVG_VIEWPORT_SELECTOR
  });
};

/**
 * Is this controller for a static font pack?
 *
 * @param {Object} variation
 * @returns {Boolean}
 */
FontpackHeroController.prototype.isStaticFontpack = function() {
  return this.static === 'true';
};

/**
 * Is the variation in one of the currently selected groups?
 *
 * @param {Object} variation
 * @returns {Boolean}
 */
FontpackHeroController.prototype.isVariationInSelectedGroups = function(variation) {
  if (!this.selectedGroupIds) {
    return false;
  }

  for (var i = 0; i < this.selectedGroupIds.length; i++) {
    if (_.contains(this.groups()[this.selectedGroupIds[i]], variation.variation_id)) {
      return true;
    }
  }

  return false;
};

/**
 * Is this variation currently selected?
 *
 * @param {Object} variation
 * @return {Boolean}
 */
FontpackHeroController.prototype.isVariationSelected = function(variation) {
  return variation.variation_id == this.selectedVariationId;
};

/**
 * Returns configurable object-position style for hero image
 *
 * @return {Object}
 */
FontpackHeroController.prototype.objectPositionStyle = function() {
  return this.heroImageFocus && this.heroImageFocus.length > 0 ? { 'object-position': this.heroImageFocus } : {};
};

/**
 * Zoom in the SVG element by ZOOM_AMOUNT.
 */
FontpackHeroController.prototype.zoomIn = function() {
  if (!this.isSvgInitialized ||
      this.svgZoomLevel >= this.MAX_ZOOM_LEVEL) {
    return;
  }

  this.svgZoomLevel += ZOOM_AMOUNT;
  this.svgElPanZoom.zoom(this.svgZoomLevel);
};

/**
 * Zoom out the SVG element by ZOOM_AMOUNT.
 */
FontpackHeroController.prototype.zoomOut = function() {
  if (!this.isSvgInitialized ||
      this.svgZoomLevel <= this.MIN_ZOOM_LEVEL) {
    return;
  }

  this.svgZoomLevel -= ZOOM_AMOUNT;
  this.svgElPanZoom.zoom(this.svgZoomLevel);
};

/**
 * Reset the zoom level back to what it originally was at page load time.
 */
FontpackHeroController.prototype.zoomReset = function() {
  if (!this.isSvgInitialized) {
    return;
  }

  this.svgElPanZoom.resize();
  this.svgElPanZoom.contain();
  this.svgElPanZoom.center();

  this.svgZoomLevel = this.svgElPanZoom.getZoom();
};

/**
 * Style all SVG groups as disabled.
 * @private
 */
FontpackHeroController.prototype._disableSvgGroups = function() {
  _.each(this.svgGroups, function(group) {
    group.style.opacity = DISABLED_OPACITY;
  });
};

/**
 * Get the groups ids for all of the groups that the variation belongs to.
 *
 * @param {Object} variation
 * @returns {String[]}
 */
FontpackHeroController.prototype._getGroupsForVariation = function(variation) {
  var groups = [];
  _.each(this.groups(), function(variationsIds, groupId) {
    if (_.contains(variationsIds, variation.variation_id)) {
      groups.push(groupId);
    }
  });

  return groups;
};

/**
 * Get the SVG group element that matches the ID.
 *
 * @private
 * @param {String} groupId
 * @returns {Element}
 */
FontpackHeroController.prototype._getSvgGroupEl = function(groupId) {
  return this.getSvgEl().contentDocument.querySelector('#' + groupId);
};

/**
 * Handles resize events on the page.
 * @private
 */
FontpackHeroController.prototype._handleWindowResize = function() {
  this.zoomReset();
  this.adjustSvgBackground();
};

/**
 * Highlight on the specific SVG group elements.
 *
 * @private
 * @param {Element[]} groupEls
 */
FontpackHeroController.prototype._highlightSvgGroupEls = function(groupEls) {
  this._disableSvgGroups();
  _.each(groupEls, function(group) {
    group.style.opacity = 1;
  });
};

/**
 * Is this group the only group that's currently selected?
 *
 * @private
 * @param {Element} group
 * @returns {Boolean}
 */
FontpackHeroController.prototype._isSelectedGroup = function(group) {
  return this.selectedGroupIds &&
    // If there's only one group selected and it's this group,
    // assume that it's selected.
    this.selectedGroupIds.length == 1 &&
    _.contains(this.selectedGroupIds, group.id);
};

/**
 * Sets all groups back to highlighted again and resets the selected groups
 * value back to empty.
 * @private
 */
FontpackHeroController.prototype._resetSelectedGroups = function() {
  this._highlightSvgGroupEls(this.svgGroups);
  this._setSelectedGroupIds([]);
};

FontpackHeroController.prototype._resetSelectedVariation = function() {
  this.selectedVariationId = null;
  this._resetSelectedGroups();
};

/**
 * Select this specific SVG group.
 */
FontpackHeroController.prototype._selectSvgGroup = function(group) {
  this._setSelectedGroupIds([group.id]);
  this._highlightSvgGroupEls([group]);

  // Deselect any variation cards that were selected
  this.selectedVariationId = null;
};

/**
 * Select the variation and highlight the groups that the variation is in.
 *
 * @private
 * @param {Object} variation
 */
FontpackHeroController.prototype._selectVariation = function(variation) {
  var self = this;
  self.selectedVariationId = variation.variation_id;
  self._setSelectedGroupIds(self._getGroupsForVariation(variation));

  var groupEls = _.map(self.selectedGroupIds, function(group) {
    return self._getSvgGroupEl(group);
  });

  self._highlightSvgGroupEls(groupEls);
  this.svgElPanZoom.fit();
  this.svgZoomLevel = this.svgElPanZoom.getZoom();
};

/**
 * Sets the selected group ids for the hero image and propagates the selected
 * ids back to the ng model.
 *
 * @private
 * @params {String[]} groupIds
 */
FontpackHeroController.prototype._setSelectedGroupIds = function(groupIds) {
  this.selectedGroupIds = groupIds;

  if (this.ngModel) {
    this.ngModel.$setViewValue(groupIds);
  }
};

module.exports = FontpackHeroController;
