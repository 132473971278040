/**
 * This directive uses features available in SVG to force a text specimen to
 * align along a specified baseline.
 *
 * For example, a list of variations with the specimen `AG` may all be aligned
 * slightly different. Using this directive fixes that issue:
 *
 * @example
 *
 *   <div class="a-card">
 *     <tk-aligned-specimen>
 *       Ag
 *     </tk-aligned-specimen>
 *   </div>
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    restrict: 'E',
    templateUrl: '/angular_templates/directives/tk_aligned_specimen.html',
    transclude: true,
    link: function(scope, el, attrs, ctrl, transclude) {
      scope.text = transclude().text();
    }
  };
}

module.exports = Directive;
