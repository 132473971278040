var _ = require('underscore');

/**
 * Clicking on a `dropdown-base-item` will automatically close the dropdown.
 *
 * `dropdown-base-item` must be nested inside of an element with the
 * `dropdown-base` directive.
 *
 * See `dropdown-base` for more documentation.
 *
 * @ngInject
 */
function DropdownBaseItem() {
  return {
    link: function(scope, element, attrs, dropdownBaseController) {
      element.addClass(dropdownBaseController.getMenuItemClass());

      var value = scope.$eval(attrs.value);

      element.on('click', function() {
        dropdownBaseController.toggleMenu(false);

        checkValue();
      });

      // If the menu item doesn't have a value set, don't worry about
      // updating the value of the overall menu.
      function checkValue() {
        if (value) {
          dropdownBaseController.updateValue(value);
        }
      }
      // If the menu item has a value attached to it, then watch the overall
      // menu's current value and set the selected class based on that value.
      if (value) {
        scope.$watch(function() {
          return dropdownBaseController.getValue();
        }, function() {
          element.toggleClass(
            dropdownBaseController.getMenuItemSelectedClass(),
            _.isEqual(dropdownBaseController.getValue(), value));
        });
      }
    },

    require: '^dropdownBase',
    restrict: 'A'
  };
}

module.exports = DropdownBaseItem;
